export const colors = {
  darkRed100: "#350009",
  darkRed90: "#530014",
  darkRed80: "#780021",
  darkRed70: "#a70032",
  darkRed60: "#de0045",
  darkRed50: "#ff4969",
  darkRed40: "#ff8292",
  darkRed30: "#ffb2ba",
  darkRed20: "#ffd6d9",
  darkRed10: "#fff0f1",
  darkBlue100: "#00162d",
  darkBlue90: "#002749",
  darkBlue80: "#003b69",
  darkBlue70: "#005493",
  darkBlue60: "#0072c4",
  darkBlue50: "#0091f7",
  darkBlue40: "#6babff",
  darkBlue30: "#a8c8ff",
  darkBlue20: "#d1e0ff",
  darkBlue10: "#eff4ff",
  lightGreen100: "#0d1902",
  lightGreen90: "#192a05",
  lightGreen80: "#28400b",
  lightGreen70: "#3b5b13",
  lightGreen60: "#517b1e",
  lightGreen50: "#689c28",
  lightGreen40: "#7aba00",
  lightGreen30: "#93da3c",
  lightGreen20: "#a6f645",
  lightGreen10: "#dcfec3",
  lightBlue100: "#00191a",
  lightBlue90: "#002b2d",
  lightBlue80: "#004043",
  lightBlue70: "#005c5f",
  lightBlue60: "#007c80",
  lightBlue50: "#009ea3",
  lightBlue40: "#00bbc2",
  lightBlue20: "#1cf7ff",
  lightBlue30: "#00dce3",
  lightBlue10: "#cefcff",
  darkGreen100: "#001b05",
  darkGreen90: "#002d0d",
  darkGreen80: "#004317",
  darkGreen70: "#006025",
  darkGreen60: "#008134",
  darkGreen50: "#00a444",
  darkGreen40: "#00c252",
  darkGreen30: "#00e562",
  darkGreen20: "#43ff7c",
  darkGreen10: "#d1ffd9",
  webGreen50: "#6c9c7e",
  webGreen40: "#558817",
  webGreen20: "#65a518",
  webGreen10: "#c1daa5",
  gray100: "#151515",
  gray90: "#262626",
  gray80: "#3d3d3d",
  gray70: "#595959",
  gray60: "#787878",
  gray50: "#969696",
  gray40: "#b0b0b0",
  gray30: "#cccccc",
  gray20: "#e3e3e3",
  gray10: "#f5f5f5",
  alertsYellow50: "#cd7b00",
  alertsYellow40: "#f29200",
  alertsRed60: "#e1001c",
  alertsRed50: "#ff4c53",
  alertsRed40: "#ff8387",
  alertsOrange50: "#e66a01",
  alertsOrange40: "#ff884f",
  white: "#ffffff",
  black: "#000000",
  focusOutline: "#396eb8",
};
