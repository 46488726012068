import React, { useState, useEffect } from "react";
import { importChunkByName } from "./FAIconImports";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import {
  library,
  findIconDefinition,
  IconName,
  IconPrefix,
  IconDefinition,
} from "@fortawesome/fontawesome-svg-core";
import { fa500px } from "@fortawesome/free-brands-svg-icons/fa500px";
import { faAccessibleIcon } from "@fortawesome/free-brands-svg-icons/faAccessibleIcon";
import { faPhone } from "@fortawesome/pro-light-svg-icons/faPhone";
import { faMailbox } from "@fortawesome/pro-light-svg-icons/faMailbox";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope";
import { faChevronUp } from "@fortawesome/pro-light-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons/faChevronDown";
import { faSearchPlus } from "@fortawesome/pro-light-svg-icons/faSearchPlus";
import { faImages } from "@fortawesome/pro-light-svg-icons/faImages";
import { faExternalLink } from "@fortawesome/pro-light-svg-icons/faExternalLink";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faGlobe } from "@fortawesome/pro-light-svg-icons/faGlobe";
import { faSignIn } from "@fortawesome/pro-light-svg-icons/faSignIn";
import { faArrowToBottom } from "@fortawesome/pro-light-svg-icons/faArrowToBottom";
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons/faLongArrowRight";
import { faComments } from "@fortawesome/pro-light-svg-icons/faComments";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faPaypal } from "@fortawesome/free-brands-svg-icons/faPaypal";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle";

library.add(
  fa500px,
  faAccessibleIcon,
  faArrowToBottom,
  faChevronDown,
  faChevronUp,
  faComments,
  faEnvelope,
  faExternalLink,
  faGlobe,
  faImages,
  faLinkedin,
  faLongArrowRight,
  faMailbox,
  faPaypal,
  faPhone,
  faTimes,
  faSignIn,
  faSearchPlus,
  faInfoCircle
);

export function FAIcon(props: FontAwesomeIconProps) {
  if (typeof props.icon !== "string")
    return <FontAwesomeIcon {...props} icon={props.icon} />;

  const iconName: IconName = props.icon?.toString() as IconName;
  const [iconLoading, setIconLoading] = useState<boolean>(false);
  const [importFunction, setImportFunction] = useState<
    typeof importChunkByName | null
  >(null);
  const [importIconFunction, setImportIconFunction] = useState<
    (() => Promise<IconDefinition[]>) | null
  >(null);

  useEffect(() => {
    let mounted = true;
    import(/* webpackChunkName: "zzz.faImport" */ "./FAIconImports").then(
      (exports) => mounted && setImportFunction(() => exports.importChunkByName)
    );
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (importIconFunction) {
      importIconFunction()
        .then((icons) => library.add(...icons))
        .finally(() => mounted && setIconLoading(false));
    }
    return () => {
      mounted = false;
    };
  }, [importIconFunction]);

  if (!iconName) return null;

  const iconDefinition = (["fal", "fab"] as Array<IconPrefix>)
    .map((prefix: IconPrefix) =>
      findIconDefinition({
        prefix: prefix,
        iconName: iconName,
      })
    )
    .find(Boolean);

  if (!iconDefinition && !iconLoading) {
    if (!importFunction) {
      return null;
    }

    const importIconFunction = importFunction(iconName);
    if (!importIconFunction) {
      console.error(`Cannot find icon: ${props.icon}`);
      return null;
    }
    setIconLoading(true);
    setImportIconFunction(() => importIconFunction);
    return null;
  }

  if (iconLoading) return null;

  if (!iconDefinition) {
    console.error(`Cannot find icon: ${props.icon}`);
    return null;
  }

  return <FontAwesomeIcon {...props} icon={iconDefinition} />;
}

export const FAIconStyles = (styles: any) => ({
  "svg.svg-inline--fa&": styles,
});
