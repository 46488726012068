import { DependencyList, MutableRefObject, useEffect } from "react";

export function useClickOutside<T extends HTMLElement>(
  ref: MutableRefObject<T | null>,
  callback: () => void,
  deps?: DependencyList
) {
  function handleClickOutside(event: any) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref.current, ...(deps || [])]);
}
