import React from "react";
import { createUseStyles } from "react-jss";
import { colors } from "../../../../basics/colors";
import { onBreakpoint, GUTTER_WIDTHS } from "../../../../basics/layout";
import { typography, customTypography } from "../../../../basics/typography";
import IContent from "../../../../models/IContent";

export function getFieldProps(field: IContent) {
  return {
    name: `__field_${field.contentLink.id}`,
    id: field.contentLink.guidValue,
  };
}

export function getColumnStyle(columns: string | null) {
  return columns === "three-columns"
    ? "threeCols"
    : columns === "two-columns"
    ? "twoCols"
    : "singleCol";
}

export type FormProps<T> = {
  value: T | null;
  onChange: (value: T) => void;
  isBlurred: boolean;
  setBlurred: () => void;
  isValid: true | string[];
};

export type ElementOption = {
  caption: string;
  value: string;
  checked: boolean | null;
};

export type WithOptions<T> = T & {
  options: ElementOption[];
};

export type ElementPropsWithOptions<T extends { data: any }> = T & {
  data: WithOptions<T["data"]>;
};

export type Validator = {
  type: string;
  errorMsg: string;
  model: {
    jsPattern: string;
  } | null;
};

export type WithValidators<T> = T & {
  validatorsExpanded: Validator[];
};

export type ElementPropsWithValidators<T extends { data: any }> = T & {
  data: WithValidators<T["data"]>;
};

export function hasOptions<T>(data: T): data is WithOptions<T> {
  return Boolean((data as any).options);
}

export function hasValidators<T>(data: T): data is WithValidators<T> {
  return (
    Boolean((data as any).validatorsExpanded) &&
    Array.isArray((data as any).validatorsExpanded)
  );
}

const labelTextSmall = customTypography(typography.textSmall, {
  marginBottom: 0,
});
export const useCommonFormStyles = createUseStyles({
  singleCol: {
    width: "100%",
    ...onBreakpoint("sm", {
      marginLeft: GUTTER_WIDTHS["small"],
    }),
    ...onBreakpoint("md", {
      marginLeft: GUTTER_WIDTHS["big"],
    }),
  },

  twoCols: {
    extend: "singleCol",
    ...onBreakpoint("sm", {
      width: `calc(100% / 2 - ${GUTTER_WIDTHS["small"]}px)`,
    }),
    ...onBreakpoint("md", {
      width: `calc(100% / 2 - ${GUTTER_WIDTHS["big"]}px)`,
    }),
  },
  threeCols: {
    extend: "singleCol",
    ...onBreakpoint("sm", {
      width: `calc(100% / 3 - ${GUTTER_WIDTHS["small"]}px)`,
    }),
    ...onBreakpoint("md", {
      width: `calc(100% / 3 - ${GUTTER_WIDTHS["big"]}px)`,
    }),
  },
  flexBreak: {
    flexBasis: "100%",
    height: 0,
  },
  label: {
    ...labelTextSmall,
    cursor: "pointer",
  },

  inputBox: {
    ...customTypography(
      typography.textDefault,
      { margin: 0 },
      { margin: 0 },
      { margin: 0 }
    ),
    background: colors.gray10,
    border: 0,
    borderBottom: `2px solid ${colors.black}`,
    borderRadius: 0,
    color: colors.black,
    height: "3.5rem",
    padding: "0 0.5rem",
    width: "100%",
    outline: "none",
    "&:focus~$underlinePrimary": { width: "100%" },
    "&:hover~$underlinePrimary": { width: "100%" },
  },

  underlinePrimary: {
    backgroundColor: colors.webGreen40,
    bottom: 0,
    display: "inline-block",
    height: 2,
    left: 0,
    position: "absolute",
    transition: "all .3s ease-out",
    width: 0,
    zIndex: 2,
  },

  invalidUnderlinePrimary: {
    backgroundColor: colors.alertsRed60,
    width: "100%",
  },

  validUnderlinePrimary: {
    backgroundColor: colors.webGreen40,
    width: "100%",
  },

  inputWrapper: {
    position: "relative",
    marginTop: "0.5rem",
    "& >*:last-child": {
      marginBottom: 0,
    },
    "&[class*='horizontal'] >*": {
      marginBottom: 0,
    },
  },

  validationError: {
    ...labelTextSmall,
    color: colors.alertsRed60,
    minHeight: "1rem",
    width: "100%",
  },

  validityIcon: {
    top: "50%",
    right: 0,
    position: "absolute",
    fontSize: "1.25rem",
    fontFamily: "Font Awesome\\ 5 Pro",
    fontWeight: 300,
    marginRight: "1.5rem",
    transform: "translateY(-50%)",
  },

  validIcon: {
    "&:after": {
      extend: "validityIcon",
      content: '"\\f00c"',
      color: colors.webGreen40,
    },
  },
  invalidIcon: {
    "&:after": {
      extend: "validityIcon",
      content: '"\\f00d"',
      color: colors.alertsRed60,
    },
  },
});

export function BreakFormLayout() {
  const commonFormStyles = useCommonFormStyles();
  return <div className={commonFormStyles.flexBreak} />;
}
