import { spacings } from "./spacings";
import { onBreakpoint, rem, BREAKPOINTS } from "./layout";
import { fonts } from "./fonts";
import { colors } from "./colors";

export const typography = {
  h1: {
    ...fonts.sansBold,
    fontSize: "1.5rem",
    lineHeight: 1.33,
    color: colors.gray100,
    marginTop: 0,
    marginBottom: rem(spacings.s),
    display: "block",

    ...onBreakpoint("sm", {
      fontSize: "3rem",
      lineHeight: 1.33,
      marginBottom: rem(spacings.l),
    }),
  },

  h2: {
    ...fonts.sansBold,
    fontSize: "1.25rem",
    lineHeight: 1.2,
    color: colors.gray100,
    marginTop: 0,
    marginBottom: rem(spacings.xs),
    display: "block",

    ...onBreakpoint("sm", {
      fontSize: "2.5rem",
      lineHeight: 1.2,
      marginBottom: rem(spacings.m),
    }),
  },

  h3: {
    ...fonts.sansBold,
    fontSize: "1.25rem",
    lineHeight: 1.2,
    color: colors.gray100,
    marginTop: 0,
    marginBottom: rem(spacings.xs),
    display: "block",
    ...onBreakpoint("sm", {
      fontSize: "2rem",
      lineHeight: 1.25,
      marginBottom: rem(spacings.sam),
    }),
  },

  h4: {
    ...fonts.sansBold,
    fontSize: "1.25rem",
    lineHeight: 1.2,
    color: colors.gray100,
    marginTop: 0,
    marginBottom: rem(spacings.xs),
    display: "block",
    ...onBreakpoint("sm", {
      fontSize: "1.5rem",
      lineHeight: 1.33,
      marginBottom: rem(spacings.s),
    }),
  },

  h5: {
    ...fonts.sansBold,
    fontSize: "1.25rem",
    lineHeight: 1.33,
    color: colors.gray100,
    marginTop: 0,
    marginBottom: rem(spacings.xs),
    display: "block",
    ...onBreakpoint("sm", {
      fontSize: "1.5rem",
      lineHeight: 1.33,
      marginBottom: rem(spacings.s),
    }),
  },

  h6: {
    ...fonts.sansBold,
    fontSize: "1.25rem",
    lineHeight: 1.33,
    color: colors.gray100,
    marginTop: 0,
    marginBottom: rem(spacings.xs),
    display: "block",
    ...onBreakpoint("sm", {
      fontSize: "1.5rem",
      lineHeight: 1.33,
      marginBottom: rem(spacings.s),
    }),
  },

  textXSmall: {
    ...fonts.sansRegular,
    fontSize: "0.625rem",
    lineHeight: 1.6,
    color: colors.gray100,
    marginTop: 0,
    marginBottom: rem(spacings.xxs),
    display: "block",
    ...onBreakpoint("sm", {
      fontSize: "0.875rem",
      lineHeight: 1.428,
      marginBottom: rem(spacings.xs),
    }),
    "& a": {
      color: colors.lightGreen50,
      transition: "color 0.2s ease",
      "&:hover": {
        color: colors.lightGreen40,
        textDecoration: "underline",
      },
    },
  },

  textSmall: {
    ...fonts.sansRegular,
    fontSize: "0.875rem",
    lineHeight: 1.428,
    color: colors.gray100,
    marginTop: 0,
    marginBottom: rem(spacings.xs),
    display: "block",
    ...onBreakpoint("sm", {
      fontSize: "1rem",
      lineHeight: 1.5,
      marginBottom: rem(spacings.s),
    }),
    "& a": {
      color: colors.lightGreen50,
      transition: "color 0.2s ease",
      "&:hover": {
        color: colors.lightGreen40,
        textDecoration: "underline",
      },
    },
  },

  textDefault: {
    ...fonts.sansRegular,
    fontSize: "1rem",
    lineHeight: 1.5,
    color: colors.gray100,
    marginTop: 0,
    marginBottom: rem(spacings.s),
    display: "block",

    ...onBreakpoint("sm", {
      fontSize: "1.1875rem",
      lineHeight: 1.473,
      marginBottom: rem(spacings.sam),
    }),
    "& a": {
      color: colors.lightGreen50,
      transition: "color 0.2s ease",
      "&:hover": {
        color: colors.lightGreen40,
        textDecoration: "underline",
      },
    },
  },

  textLarge: {
    ...fonts.sansRegular,
    fontSize: "1rem",
    lineHeight: 1.5,
    color: colors.gray100,
    marginTop: 0,
    marginBottom: rem(spacings.s),
    display: "block",
    ...onBreakpoint("sm", {
      fontSize: "1.5rem",
      lineHeight: 1.33,
      marginBottom: rem(spacings.sam),
    }),
    "& a": {
      color: colors.lightGreen50,
      transition: "color 0.2s ease",
      "&:hover": {
        color: colors.lightGreen40,
        textDecoration: "underline",
      },
    },
  },

  visuallyHidden: {
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    width: "1px",
    height: "1px",
    margin: "-1px",
    padding: 0,
    border: 0,
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
  },

  caption: {
    ...fonts.sansRegular,
    fontSize: "1rem",
    lineHeight: 1.5,
    color: colors.gray60,
    letterSpacing: 1.8,
    textTransform: "uppercase",
    marginTop: 0,
    display: "block",
    ...onBreakpoint("sm", {
      fontSize: "1.1875rem",
      lineHeight: 1.473,
    }),
  },

  uList: {
    paddingLeft: 0,
    marginBottom: rem(spacings.s),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.sam),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.m),
    }),
    "& li": {
      ...fonts.sansRegular,
      fontSize: "1rem",
      lineHeight: 1.5,
      color: colors.gray100,
      listStyle: "none",
      marginBottom: rem(spacings.s),
      paddingLeft: rem(spacings.xs),
      display: "block",

      ...onBreakpoint("sm", {
        fontSize: "1.1875rem",
        lineHeight: 1.473,
      }),

      "@supports (display: contents)": {
        display: "flex",
        "& *": {
          display: "contents",
        },
        "& br": {
          display: "block",
        },
      },
      "&:before": {
        position: "relative",
        fontFamily: "Font Awesome\\ 5 Pro",
        content: '"\\f178"',
        fontWeight: 300,
        color: colors.gray40,
        marginRight: rem(spacings.s),
        fontSize: "inherit",
        top: 0,
        float: "left",
        height: 28,
        whiteSpace: "nowrap",
        "@supports (display: contents)": {
          fontSize: "1.6rem",
          top: -3,
          float: "none",
        },
      },
      "& a": {
        ...fonts.sansRegular,
        fontSize: "1rem",
        lineHeight: 1.5,
        color: colors.lightGreen50,

        ...onBreakpoint("sm", {
          fontSize: "1.1875rem",
          lineHeight: 1.473,
        }),
        "&:hover": {
          color: colors.lightGreen40,
          textDecoration: "underline",
        },
      },

      "& p": {
        marginBottom: 0,
      },
    },
  },

  oList: {
    counterReset: "counter",
    paddingLeft: 0,
    marginBottom: rem(spacings.s),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.sam),
    }),

    ...onBreakpoint("md", {
      marginBottom: rem(spacings.m),
    }),
    "& li": {
      counterIncrement: "counter",
      ...fonts.sansRegular,
      fontSize: "1rem",
      lineHeight: 1.5,
      color: colors.gray100,
      listStyle: "none",
      marginBottom: rem(spacings.s),
      paddingLeft: rem(spacings.xs),
      display: "block",

      "@supports (display: contents)": {
        display: "flex",
        "& *": {
          display: "contents",
        },
      },

      ...onBreakpoint("sm", {
        fontSize: "1.1875rem",
        lineHeight: 1.473,
      }),

      "&:before": {
        position: "relative",
        content: "counter(counter) '. '",
        marginRight: rem(spacings.s),
        color: colors.gray40,
        height: 28,
        whiteSpace: "nowrap",
      },

      "& a": {
        ...fonts.sansRegular,
        fontSize: "1rem",
        lineHeight: 1.5,
        color: colors.lightGreen50,

        ...onBreakpoint("sm", {
          fontSize: "1.1875rem",
          lineHeight: 1.473,
        }),
        "&:hover": {
          color: colors.lightGreen40,
          textDecoration: "underline",
        },
      },

      "& p": {
        marginBottom: 0,
      },
    },
  },

  grayBackgroundFix: {
    "& li:before": {
      color: `${colors.gray60} !important`,
    },
  },

  darkBackgroundFix: {
    color: `${colors.white} !important`,
    "& li:before": {
      color: `${colors.white} !important`,
    },
  },
};

export function customTypography(
  style: any,
  xs?: any,
  sm?: any,
  md?: any
): any {
  return {
    ...style,
    ...xs,
    [`@media (min-width: ${BREAKPOINTS["sm"]}px)`]: {
      ...style[`@media (min-width: ${BREAKPOINTS["sm"]}px)`],
      ...sm,
    },
    [`@media (min-width: ${BREAKPOINTS["md"]}px)`]: {
      ...style[`@media (min-width: ${BREAKPOINTS["md"]}px)`],
      ...md,
    },
  };
}
