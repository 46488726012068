import React, { ReactNode, CSSProperties } from "react";
import cn from "classnames";
import { createUseStyles } from "react-jss";
import { RemNumber } from "./spacings";

type TContainer = {
  className?: string;
  style?: CSSProperties;
  isFluid?: boolean;
  noPadding?: boolean;
  dataTestid?: string;
  children: ReactNode;
};

type TRow = {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  dataTestid?: string;
};

export type XSColumnAmount = 1 | 2 | 3 | 4 | null;
export type ColumnAmount =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | null;

type TCol = {
  xs?: XSColumnAmount;
  sm?: ColumnAmount;
  md?: ColumnAmount;
  lg?: ColumnAmount;
  xl?: ColumnAmount;
  xsStart?: XSColumnAmount;
  smStart?: ColumnAmount;
  mdStart?: ColumnAmount;
  lgStart?: ColumnAmount;
  xlStart?: ColumnAmount;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  dataTestid?: string;
};

export const BREAKPOINTS = {
  sm: 768,
  md: 1025,
  lg: 1280,
  xl: 1440,
};

export const GUTTER_WIDTHS = {
  small: 16,
  big: 24,
};
export const CONTAINER_OFFSET_WIDTHS = {
  small: 16,
  big: 32,
};

export const MAX_LAYOUT_WIDTH =
  BREAKPOINTS["xl"] + 2 * CONTAINER_OFFSET_WIDTHS["big"];
export const MAX_SECTION_WIDTH = 1920;
export const MARGIN_OF_CONTAINER_LIMITED = `(min(100vw, ${MAX_SECTION_WIDTH}px) - min(100vw, ${MAX_LAYOUT_WIDTH}px))/2`;
export const MARGIN_OF_CONTAINER = `(100vw - min(100vw, ${MAX_LAYOUT_WIDTH}px))/2`;

export function getGridColumnWidth(
  breakpoint: "xs" | "sm" | "md",
  isFluid?: boolean
) {
  if (breakpoint === "xs")
    return `(100vw - 2 * ${CONTAINER_OFFSET_WIDTHS["small"]}px - 11 * ${GUTTER_WIDTHS["small"]}px) / 4`;

  if (breakpoint === "sm")
    return `(100vw - 2 * ${CONTAINER_OFFSET_WIDTHS["big"]}px - 11 * ${GUTTER_WIDTHS["small"]}px) / 12`;

  const MAX_LAYOUT_WIDTH =
    BREAKPOINTS["xl"] + 2 * CONTAINER_OFFSET_WIDTHS["big"];
  const MARGIN_OF_CONTAINER = isFluid
    ? "0px"
    : `(100vw  - min(100vw, ${MAX_LAYOUT_WIDTH}px))`;

  return `(100vw - ${MARGIN_OF_CONTAINER} - 2 * ${CONTAINER_OFFSET_WIDTHS["big"]}px - 11 * ${GUTTER_WIDTHS["big"]}px) / 12`;
}

export function onBreakpoint(
  breakpoint: keyof typeof BREAKPOINTS,
  styles: any
) {
  return {
    [`@media (min-width: ${BREAKPOINTS[breakpoint]}px)`]: styles,
  };
}

export function onBefore(breakpoint: keyof typeof BREAKPOINTS, styles: any) {
  return {
    [`@media (max-width: ${BREAKPOINTS[breakpoint] - 1}px)`]: styles,
  };
}

export function rem(value: RemNumber) {
  return `${value.valueOf()}rem`;
}

const col = (size: number): CSSProperties =>
  ({
    position: "relative",
    gridColumnEnd: `span ${size}`,
  } as CSSProperties);

const colStart = (offset: number) => ({
  gridColumnStart: offset,
});

const useStyles = createUseStyles({
  container: {
    width: "100%",
    maxWidth: BREAKPOINTS["xl"] + 2 * CONTAINER_OFFSET_WIDTHS["big"],
    margin: "0 auto",
    padding: `0 ${CONTAINER_OFFSET_WIDTHS["small"]}px`,
    ...onBreakpoint("sm", { padding: `0 ${CONTAINER_OFFSET_WIDTHS["big"]}px` }),
  },

  containerFluid: {
    maxWidth: "100%",
    margin: "auto",
  },

  containerNoPadding: {
    padding: 0,
    "& [class^='row']": {
      columnGap: 0,
    },
  },

  row: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    columnGap: GUTTER_WIDTHS["small"],

    ...onBreakpoint("sm", {
      gridTemplateColumns: "repeat(12, 1fr)",
    }),

    ...onBreakpoint("md", {
      columnGap: GUTTER_WIDTHS["big"],
    }),
  },

  ...[1, 2, 3, 4]
    .map((size) => ({
      [`col-xs-${size}`]: {
        ...col(size),
      },
      [`start-xs-${size - 1}`]: {
        ...colStart(size),
      },
    }))
    .reduce((acc, value) => ({ ...acc, ...value }), {}),

  ...["sm", "md", "lg", "xl"]
    .map((breakpoint) =>
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        .map((size) => ({
          [`col-${breakpoint}-${size}`]: onBreakpoint(
            breakpoint as keyof typeof BREAKPOINTS,
            col(size)
          ),

          [`start-${breakpoint}-${size - 1}`]: onBreakpoint(
            breakpoint as keyof typeof BREAKPOINTS,
            colStart(size)
          ),
          [`start-${breakpoint}-clear`]: onBreakpoint(
            breakpoint as keyof typeof BREAKPOINTS,
            { gridColumnStart: "auto" }
          ),
        }))
        .reduce((acc, value) => ({ ...acc, ...value }), {})
    )
    .reduce((acc, value) => ({ ...acc, ...value }), {} as any),
});

export function Container(props: TContainer) {
  const styles = useStyles();
  return (
    <div
      className={cn(
        props.isFluid ? styles.containerFluid : styles.container,
        props.noPadding && styles.containerNoPadding,
        props.className
      )}
      style={props.style}
      data-testid={props.dataTestid}
    >
      {props.children}
    </div>
  );
}

export function Row(props: TRow) {
  const styles = useStyles();
  return (
    <div
      className={cn(styles.row, props.className)}
      style={props.style}
      data-testid={props.dataTestid}
    >
      {props.children}
    </div>
  );
}

export function Col(props: TCol) {
  const styles = useStyles();
  const xsCol = props.xs || 4;
  const smCol = props.sm || (props.sm !== null && xsCol * 3);
  const mdCol = props.md || (props.md !== null && smCol);
  const lgCol = props.lg || (props.lg !== null && mdCol);
  const xlCol = props.xl || (props.xl !== null && lgCol);

  const xsStart = props.xsStart || 0;
  const smStart =
    props.smStart !== null ? props.smStart || xsStart * 3 : "clear";
  const mdStart = props.mdStart !== null ? props.mdStart || smStart : "clear";
  const lgStart = props.lgStart !== null ? props.lgStart || mdStart : "clear";
  const xlStart = props.xlStart !== null ? props.xlStart || lgStart : "clear";

  return (
    <div
      className={cn(
        styles[`col-xs-${xsCol}`],
        styles[`col-sm-${smCol}`],
        styles[`col-md-${mdCol}`],
        styles[`col-lg-${lgCol}`],
        styles[`col-xl-${xlCol}`],
        xsStart && styles[`start-xs-${xsStart}`],
        smStart && styles[`start-sm-${smStart}`],
        mdStart && styles[`start-md-${mdStart}`],
        lgStart && styles[`start-lg-${lgStart}`],
        xlStart && styles[`start-xl-${xlStart}`],
        props.className
      )}
      style={props.style}
      data-testid={props.dataTestid}
    >
      {props.children}
    </div>
  );
}
