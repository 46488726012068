export class RemNumber extends Number {
  add(b: RemNumber): RemNumber {
    return new RemNumber(this.valueOf() + b.valueOf());
  }
  minus(b: RemNumber): RemNumber {
    return new RemNumber(this.valueOf() - b.valueOf());
  }

  times(b: number): RemNumber {
    return new RemNumber(this.valueOf() * b.valueOf());
  }

  divide(b: number): RemNumber {
    return new RemNumber(this.valueOf() / b.valueOf());
  }

  toString(): string {
    return `${this.valueOf()}rem`;
  }
}

export const spacings = {
  xxs: new RemNumber(0.25),
  xs: new RemNumber(0.5),
  s: new RemNumber(1),
  sam: new RemNumber(1.5),
  m: new RemNumber(2),
  l: new RemNumber(2.5),
  xl: new RemNumber(3),
  xxl: new RemNumber(4),
};
