export const fonts = {
  sansLight: {
    fontFamily: "HDIGerlingSansLight, Arial, sans-serif",
  },
  sansRegular: {
    fontFamily: "HDIGerlingSansRegular, Arial, sans-serif",
  },
  sansBold: {
    fontFamily: "HDIGerlingSansBold, Arial, sans-serif",
  },
};
