import React, { useState, useRef, ReactNode, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { customTypography, typography } from "../../basics/typography";
import { colors } from "../../basics/colors";
import { rem } from "../../basics/layout";
import { spacings } from "../../basics/spacings";
import cn from "classnames";
import { useClickOutside } from "../../hooks/useClickOutside";
import { FAIcon } from "../FAIcon/FAIcon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Key } from "../../basics/keys";
import { useCommonFormStyles } from "../Block/Forms/Elements/common";

const OPTION_ELEMENT_HEIGHT = 24 + 16 * 2 + 2;

const useStyles = createUseStyles({
  selectCustom: {
    zIndex: 10,
    position: "relative",
  },
  underlinePrimary: {
    backgroundColor: colors.webGreen40,
    bottom: -2,
    display: "inline-block",
    height: 2,
    left: 0,
    position: "absolute",
    transition: "all .3s ease-out",
    width: 0,
    zIndex: 20,
  },
  underlinePrimaryFocused: {
    width: "100%",
  },
  select: {
    position: "relative",
    borderBottom: `2px solid ${colors.black}`,
    zIndex: 20,
    marginBottom: 0,
  },
  defaultText: customTypography(
    typography.textDefault,
    { marginBottom: 0 },
    { marginBottom: 0 },
    { marginBottom: 0 }
  ),
  selectNative: {
    background: colors.gray10,
    border: 0,
    borderBottom: `2px solid ${colors.black}`,
    borderRadius: 0,
    color: colors.black,
    padding: `${rem(spacings.xs)} ${rem(spacings.s)}`,
    width: "100%",
    outline: "none",
    position: "absolute",
    top: 0,
    left: 0,
    ...customTypography(typography.textSmall, {
      marginBottom: 0,
    }),
  },
  customTrigger: {
    height: "calc(100% - 2px)",
    padding: `${rem(spacings.xs)} ${rem(spacings.s)} !important`,
    borderTop: `2px solid ${colors.gray10} !important`,
    "& $customOptionLabel": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  customOptionLabel: {},
  customOptionLabelMargin: {
    marginRight: rem(spacings.s),
  },
  customOptionsWrapper: {
    background: colors.gray10,
    border: 0,
    borderBottom: `2px solid ${colors.black}`,
    borderRadius: 0,
    color: colors.black,
    minWidth: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    boxShadow: "0 0 4px #e9e1f8",
    zIndex: 30,
    display: "none",
    maxHeight: 5.5 * OPTION_ELEMENT_HEIGHT,
    overflowY: "auto",
    "&$isActive": {
      display: "block",
    },
  },
  customOption: {
    width: "100%",
    padding: rem(spacings.s),
    color: colors.gray60,
    fontWeight: "normal",
    backgroundColor: colors.gray10,
    borderTop: `2px solid ${colors.gray30}`,
    textAlign: "left",
    alignItems: "center",
    ...customTypography(
      typography.textSmall,
      {
        marginBottom: 0,
      },
      {
        marginBottom: 0,
      }
    ),
    "&$isActive": {
      color: colors.black,
    },
    "&$isHover": {
      backgroundColor: colors.white,
    },
    display: "flex",
  },
  customOptionIconWrapper: {
    width: 20,
    lineHeight: 0,
  },
  customOptionPreIconWrapper: {
    marginRight: rem(spacings.s),
  },
  customOptionPostIconWrapper: {
    marginLeft: "auto",
  },
  isActive: {},
  isHover: {},
  visuallyHidden: {
    ...typography.visuallyHidden,
  },
});

export interface SelectOption {
  value: string;
  label: string;
  icon?: string | ReactNode;
}

export function Select(props: {
  label: string | ReactNode;
  options: Array<SelectOption>;
  selected: string;
  setSelected: (props: string) => void;
  customTriggerIcon?: string;
  iconOnlyTrigger?: boolean;
  className?: string;
  id?: string;
  name?: string;
  onBlur?: () => void;
}) {
  const selectedOptionIdx = props.selected
    ? props.options.findIndex((opt) => opt.value === props.selected)
    : -1;
  const selectedOption =
    selectedOptionIdx > -1 ? props.options[selectedOptionIdx] : undefined;
  const [isOpen, setOpen] = useState<boolean>(false);
  const [wasFocused, setWasFocused] = useState<boolean>(false);
  const [isFocused, setFocused] = useState<boolean>(false);
  const [hoveredElementIdx, setHoveredElementIdx] =
    useState<number>(selectedOptionIdx);
  const [scrollTopElementIdx, setScrollTopElementIdx] = useState<number>(
    selectedOptionIdx > 0 ? selectedOptionIdx : 0
  );
  const labelRef = useRef<HTMLLabelElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const styles = useStyles();
  const commonFormStyles = useCommonFormStyles();

  function openDropdown() {
    setOpen(true);
    setScrollTopElementIdx(0);
  }

  useClickOutside(
    labelRef,
    () => {
      setOpen(false);
      setFocused(false);
      wasFocused && props.onBlur?.();
      setWasFocused(false);
    },
    [wasFocused, props.onBlur]
  );

  useEffect(() => {
    optionsRef.current?.scrollTo?.(
      0,
      scrollTopElementIdx * OPTION_ELEMENT_HEIGHT
    );
  }, [isOpen, scrollTopElementIdx, optionsRef.current]);

  function scrollUp(hovElementIdx: number) {
    if (hovElementIdx > scrollTopElementIdx + 4) scrollDown(hovElementIdx);
    if (hovElementIdx - scrollTopElementIdx >= 0) return;
    setScrollTopElementIdx(hovElementIdx);
  }

  function scrollDown(hovElementIdx: number) {
    if (hovElementIdx < scrollTopElementIdx) scrollUp(hovElementIdx);
    if (hovElementIdx - scrollTopElementIdx < 4) return;
    setScrollTopElementIdx(hovElementIdx - 4);
  }

  function supportKeyboardNavigation(
    event: React.KeyboardEvent<HTMLLabelElement>
  ) {
    event.preventDefault();

    switch (event.keyCode) {
      case Key.ArrowDown: {
        const hovElementIdx = (hoveredElementIdx + 1) % props.options.length;
        setHoveredElementIdx(hovElementIdx);
        scrollDown(hovElementIdx);
        break;
      }
      case Key.ArrowUp: {
        const hovElementIdx =
          (props.options.length + hoveredElementIdx - 1) % props.options.length;
        setHoveredElementIdx(hovElementIdx);
        scrollUp(hovElementIdx);
        break;
      }
      case Key.Enter:
      case Key.Space:
        if (!isOpen) {
          setHoveredElementIdx(0);
          openDropdown();
          return;
        }

        props.setSelected(props.options?.[hoveredElementIdx]?.value || "");
        setOpen(false);
        break;
      case Key.Escape:
        setOpen(false);
        break;
      default:
    }
  }

  return (
    <label
      className={cn(styles.select, props.className)}
      onKeyDown={supportKeyboardNavigation}
      ref={labelRef}
    >
      <span className={styles.visuallyHidden}>{props.label}</span>
      <div className={commonFormStyles.inputWrapper}>
        <select
          id={props.id}
          name={props.name}
          className={cn(styles.defaultText, styles.selectNative)}
          value={props.selected}
          onChange={(event) => props.setSelected(event.target.value)}
          onFocus={() => {
            setWasFocused(true);
            setFocused(true);
          }}
          onBlur={() => setFocused(false)}
        >
          <option disabled value="">
            {props.label}
          </option>
          {props.options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <div className={styles.selectCustom}>
          <SelectOption
            label={
              props.iconOnlyTrigger ? "" : selectedOption?.label || props.label
            }
            preIcon={props.iconOnlyTrigger ? undefined : selectedOption?.icon}
            onClick={(e) => {
              isOpen ? setOpen(false) : openDropdown();
              setWasFocused(true);
              e.currentTarget.blur();
            }}
            postIcon={
              <FAIcon
                icon={
                  (props.customTriggerIcon as IconProp) ||
                  (isOpen ? "chevron-up" : "chevron-down")
                }
              />
            }
            className={cn(styles.customTrigger, "selected-value")}
          />
          <div
            className={cn(
              styles.customOptionsWrapper,
              isOpen && styles.isActive
            )}
            aria-expanded={isOpen}
            ref={optionsRef}
          >
            {props.options.map((option, idx) => (
              <SelectOption
                key={option.value}
                label={option.label}
                preIcon={option.icon}
                isHovered={hoveredElementIdx === idx}
                onMouseEnter={() => setHoveredElementIdx(idx)}
                onMouseLeave={() => setHoveredElementIdx(-1)}
                onClick={() => {
                  props.setSelected(option.value);
                  setOpen(false);
                }}
              />
            ))}
          </div>
          <div
            className={cn(
              styles.underlinePrimary,
              isFocused && styles.underlinePrimaryFocused
            )}
          />
        </div>
      </div>
    </label>
  );
}

function SelectOption(props: {
  label: string | ReactNode;
  preIcon?: string | ReactNode;
  postIcon?: string | ReactNode;
  isHovered?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) {
  const styles = useStyles();

  return (
    <button
      type="button"
      className={cn(
        styles.customOption,
        props.isHovered && styles.isHover,
        props.className,
        "select-option"
      )}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.preIcon && (
        <OptionIcon
          icon={props.preIcon}
          className={styles.customOptionPreIconWrapper}
        />
      )}
      <div
        className={cn(
          styles.customOptionLabel,
          props.postIcon && props.label && styles.customOptionLabelMargin
        )}
      >
        {props.label || "\u00A0"}
      </div>
      {props.postIcon && (
        <OptionIcon
          icon={props.postIcon}
          className={styles.customOptionPostIconWrapper}
        />
      )}
    </button>
  );
}

type OptionIconProp = string | ReactNode;

function OptionIcon(props: { icon: OptionIconProp; className?: string }) {
  const styles = useStyles();

  return (
    <div className={cn(styles.customOptionIconWrapper, props.className)}>
      {typeof props.icon === "string" ? (
        <FAIcon icon={props.icon as IconProp} />
      ) : (
        props.icon
      )}
    </div>
  );
}
